import { ResponseUserGrantDto } from '@kerberos-compliance/lib-adp-shared/core/iam/authorization/dtos/response.user-grant.dto';
import { NamedRole, NamedRoleMap } from '@kerberos-compliance/lib-adp-shared/core/iam/authorization/enums/named-role';
import { AuthStateTokens } from '@/auth/store/reducers/auth-kerberos.reducers';

export type AuthStateTokenAndOwnGrant = {
  authStateTokens: AuthStateTokens;
  ownGrant: ResponseUserGrantDto | undefined;
};

export enum FeaturesAmlDeskWithGrants {
  KnowYourCustomer = 'KnowYourCustomer',
  RiskAnalysis = 'RiskAnalysis',
  LocationAnalysis = 'LocationAnalysis',
}

export const FeatureRoleMap: Record<FeaturesAmlDeskWithGrants, NamedRole[]> = {
  [FeaturesAmlDeskWithGrants.KnowYourCustomer]: [NamedRoleMap.WorkItemUser],
  [FeaturesAmlDeskWithGrants.RiskAnalysis]: [NamedRoleMap.RiskAnalysisCustomer],
  [FeaturesAmlDeskWithGrants.LocationAnalysis]: [NamedRoleMap.LocationAnalysisUser],
};
